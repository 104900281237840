.noCopy {
  user-select: none;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
}

.rounded-video {
  border-radius: 10px;
}

#video-controls {
  display: flex;
  justify-content: center;
  /* Add other styles as needed */
}

/* Add this to your CSS file */
.accordion-layout-left, .accordion-layout-right {
  transition: all 0.3s ease;
}

.accordion-container.expanded + .accordion-layout-left {
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.accordion-container.expanded {
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.fc-event-title {
  font-weight: 700;
  //box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  //font-color: #000 !important;
}

.rct-tree-item-arrow-path {
  fill: #000000 !important;
}

.subsection-tile {
  background-color: #f2f2f2;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  padding: .7rem 1.2rem !important;
  // font-size: 75%;
  // font-weight: 700;
  // line-height: 1;
  // vertical-align: baseline;
  border-radius: .50rem !important; 
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.ag-select-list {
  background-color: #fff;
}

.btn {
  font-weight: 600;
}

.font-weight-normal {
  font-weight: 400 !important;
}

#video-controls button {
  background: none;
  border: none;
  cursor: pointer;
  /* Add other styles as needed */
}

.uppy-Container {
  width: unset !important;
  /* width: fit-content; */
}


input:disabled {
  background-color: #e9ecef !important;
}

input:disabled.form-control-plaintext {
  background-color: transparent !important;
}

.jodit-container {
  p {
    font-size: 14px;
    color: #1f1f1f;

    ins {
      background-color: #ffffa3;
      color: #101010;
      text-decoration-color: #00c4ff;
    }
    del {
      text-decoration: line-through;
      text-decoration-color: #c40000;
    }
  }
}

.transcript-content .transcript-cue {
  line-height: 1.6;
  display: inline;
}
.transcript-content .search-match {
  background-color: #d4e6ff;
  border-radius: 2px;
}



.transcript-content .active-match {
    background-color: #a7c9f9 !important;  
}


.transcript-content .active-cue {
  background-color: #f0f7ff !important;
}



.jodit-placeholder {
  //not sure why this had a z index value set, it was set from the package. I removed it because it caused issues with overlays.
  z-index: unset !important;
}

.readOnly .tox-tinymce {
  border: none !important;
}

.define {
  font-weight: 700;
  font-style: italic;
  color: black;
}

.stat-background{
  background: rgba(197,197,197,0.48);
  padding: 20px;
}

.tooltip-def {
  color: #3b008a;
  display: inline;
  border-bottom: 1px dotted black;
  cursor: help;
  margin: 0;
  padding: 0;
}

.text-c-purple {
        color: #610098;
    }


#rfs-btn {
  background-color: #f4f7fa;
  height: 42.5px;
}

.center {
  justify-content: center;
  justify-items: center;
  justify-self: center;
  text-align: center;
  align-content: center;
  align-items: center;
  alignment: center;
  align-self: center;
}

.form-font{
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}


.create_stat_height {

  height: 47px !important;
  padding-left: 12px !important;
}

.button_height_for_fields {
  height: 50px !important;
}


.text-blackish {
  color: #1f1f1f !important;
}

.text-blackishish {
  color: #505050 !important;
}


.fc .fc-daygrid-day-number {
  color: #232325 !important;

}

.fc .fc-col-header-cell-cushion {

  color: #232325 !important;

}


.powered-by {
  display: table;
  margin: 0 auto !important;
  font-size: 60%;
  color: #a9b7d0;
  font-weight: 300 !important;

}

.force-center {
  /* Text centering for inline or inline-block elements within */
  text-align: center;
  justify-items: center; /* Center grid items along the inline (row) axis */
  justify-content: center; /* Align the grid along the inline (row) axis */
  align-items: center;
  align-content: center; /* Align the grid content along the block (column) axis */
}

.force-right {
  /* Text centering for inline or inline-block elements within */
  text-align: right;
  justify-items: right; /* Center grid items along the inline (row) axis */
  justify-content: right; /* Align the grid along the inline (row) axis */
  align-items: end;
  align-content: end; /* Align the grid content along the block (column) axis */
}

.force-vertical-center {
  align-items: center; // Vertically center the child content
  display: flex;

}

.force-left {
  text-align: left;
  justify-items: left;
  justify-content: left;
  align-items: start;
  align-content: start;
}

.table-max-width {
  table-layout: fixed;
  width: 100%;
}

.table-max-width th {
  max-width: 35% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.word-wrap {
  white-space: break-spaces !important;
  word-wrap: break-word !important; // For older browsers
  overflow-wrap: break-word !important; // Standard way to handle long words in modern browsers
}

.no-wrap {
  white-space: nowrap !important;
}

.no-wrap-centered {
  white-space: nowrap;
  //position: absolute;
  left: 5%;
  transform: translateX(-5%);
  /* Text centering for inline or inline-block elements within */
  text-align: right;
  justify-items: right; /* Center grid items along the inline (row) axis */
  justify-content: right; /* Align the grid along the inline (row) axis */
  align-items: end;
  align-content: end; /* Align the grid content along the block (column) axis */
}


.btn-none {
  color:unset !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndbarnode {
  //height: 20px;
  padding: 3px 6px;
  color: #6c757d;
  border-radius: 30px;
  display: block;
  vertical-align: middle;
  border: 1px solid #6c757d;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: grab;
  transition: all 0.3s ease-in-out;
  z-index: 99;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}



//stat print classes

.print-spacer {
  display: none;
}

@media print {
  html, body {
    -webkit-print-color-adjust: exact;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
  }
  .printChart {
    padding-bottom: 0!important;
  }

  .print-stat-col{
    padding: 0 !important;
    margin: 0 !important;
  }

  .confettiCard{
    margin: 0 !important;
    padding: 0 !important;
  }

  .print-stat_header{
    //size:70px !important;
    //font-size: 22px !important;
    //white-space: break-spaces !important;
  }

  .print-spacer {
    display: block;
  }


  .printhide {
    display: none !important;
    visibility: hidden !important;
  }
  .printinvisible {
    visibility: hidden !important;
  }
  .printshow {
    display: block !important;
    visibility: visible !important;
    background: none;
    background-color: transparent;
    float:None !important;
  }
  .printfull, .printChart {
    width: 100vw !important;
    max-width: 100vw !important;
    box-sizing: border-box;
  }

  .printfull {
    min-height: 8.5in !important;

  }
}


.select__control{
  background-color: rgb(242, 245, 248) !important;
  //border-style: none !important;
}
.select__menu{
  background-color: rgb(242, 245, 248) !important;
  z-index: 500 !important;
}

.swal2-container {
  z-index: 2000 !important;
}

.react-form-builder{
  background: transparent  !important;
}

.rct-tree-item-button{
  height:100% !important;
}

.rct-tree-item-title-container-selected .rct-tree-item-button::before {
  height:100% !important;
  top: 0 !important;
}



:root {
  --rct-item-height: 100% !important;
}



.rct-dark {
  --rct-color-focustree-item-selected-bg: rgba(91, 97, 101, 0.48) !important;
  --rct-color-focustree-item-hover-bg: rgba(85, 93, 96, 0.30) !important
}


//.rct-tree-drag-between-line {
//  position: absolute;
//  right: 0;
//  top: unset !important;
//  height: 4px;
//  background-color: var(--rct-color-drag-between-line-bg);
//}


.react-flow__attribution {
  display: none;
}

/* Add this to your CSS file */
.offcanvas-menu {
  position: fixed;
  top: 0;
  left: -280px; /* Start off-screen */
  width: 280px;
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  transition: left 0.4s ease;
  z-index: 1050; /* Higher than most elements */
}

.offcanvas-menu.open {
  left: 0; /* Slide onto the screen */
}

//.offcanvas-backdrop {
//  position: fixed;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  background: rgba(0, 0, 0, 0.5);
//  z-index: 1040; /* Below the offcanvas menu */
//}

.flow-set-bg-text, .flow-set-bg-text * {
  color: rgb(17, 17, 17) !important; /* This will override other CSS rules */
}

input[type="date"]::before {
    content: none !important; /* Removes content if not needed */
    /* Or override other properties as needed */
}

//.bg-light {
//  background-color: #ebeced !important;
//}

.modal-content {
  box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26);
}

.sc-iemWCZ {
  color: rgba(85, 93, 96, 1) !important

}
.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  background-color: #F1F3F4; /* Match the audio player background */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding-bottom: 30px; /* Space for buttons */
  margin-bottom: 10px;
}

.audio-element {
  width: 100%;
  display: block;
  font-size: 16px !important;
}

.playback-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  // background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 5px 0;
  padding-bottom: 10px; /* Space for buttons */

}

.playback-controls button {
  margin: 0 5px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  background-color: #75787a;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.playback-controls button.active {
  background-color: #4a4c4e; /* Darker color for active button */
}

.playback-controls button:hover {
  background-color: #0056b3;
}


.tippy-box {
  background-color: white;
  color: black;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tippy-box[data-theme~='light'] {
  background-color: white;
  color: black;
}

.tippy-box[data-theme~='light'] .tippy-arrow {
  color: white;
}

.tippy-box ul {
  margin: 0;
  padding-left: 1.2em;
}

.tooltip-content {
  padding: 8px;
  max-width: 300px;
}

.tooltip-content ul {
  margin: 4px 0;
  padding-left: 20px;
}

.tooltip-content li {
  margin: 2px 0;
}

.custom-nested-dropdown {
  width: 100%;
  background-color: #f4f7fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-height: 42px;

  .dropdown-toggle {
      width: 100%;
      padding: 8px 12px;
      background-color: transparent;
      border: none;
      text-align: left;
      color: #333;

      &:hover {
          border-color: #b1bbc4;
      }

      &:focus {
          border-color: $primary-color;;
          box-shadow: 0 0 0 0.2rem transparentize($primary-color, 0.25) !important;
          outline: none;
      }
  }

  .dropdown-menu {
      background: #fff;
      border: 1px solid #ced4da;
      border-radius: 4px;
      box-shadow: 0 0 15px 0 transparentize($primary-color, 0.25) !important;
      z-index: 1050;
      padding: 5px 0;

      .dropdown-item {
          padding: 8px 12px;
          color: #333;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
              background-color: #f4f7fa;
              color: $primary-color;
          }

          &.active {
              background-color: transparentize($primary-color, 0.25);
              color: $primary-color;
              font-weight: 500;
          }

          &.has-children {
              position: relative;

              &:after {
                  content: '►';
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  font-size: 10px;
              }
          }
      }
  }
}

.custom-dropdown {
  width: 100%;
  
  .dropdown-trigger-btn {
      width: 100%;
      height: calc(1.5em + 1.25rem + 2px);
      padding: 0.625rem 0.75rem;
      background-color: #f4f7fa;
      border: 1px solid #ced4da;
      border-radius: 4px;
      color: #333;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.15s ease-in-out;
      
      &:hover {
          border-color: #b1bbc4;
      }
      
      &:focus {
          border-color: $primary-color;
          box-shadow: 0 0 0 0.2rem transparentize(#007bff, 0.75);
          outline: none;
      }
      
      &:disabled {
          background-color: #e9ecef;
          cursor: not-allowed;
      }
  }
}

// Override library's default styles
.rnd-menu {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 transparentize($primary-color, 0.25);
  z-index: 1050;
  
  .rnd-item {
      padding: 8px 12px;
      color: #333;
      cursor: pointer;
      transition: all 0.3s;
      
      &:hover {
          background-color: #f4f7fa;
          color: $primary-color;
      }
      
      &.active {
        background-color: transparentize($primary-color, 0.1);
        color: $primary-color;
          font-weight: 500;
      }
  }
}

// Add/update in your _custom.scss
.rnd-menu {
  // ... existing styles ...
  
  .rnd-item {
      // ... existing styles ...
      
      &.active {
          background-color: transparentize($primary-color, 0.1);
          color: $primary-color;
          font-weight: 500;
      }
  }
}


.uppy-DragDrop-container {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  border: none !important;
}

.uppy-DragDrop-inner {
  text-align: center;
  padding: 80px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 3px dashed white;
  border-radius: 15px;
}

.uppy-DragDrop-label {
  color: white;
  font-size: 1.5em;
  margin-bottom: 0;
}

.uppy-DragDrop-browse {
  display: none;
}

.video-max-height {
  max-height: 600px;
}

.rnd__submenu .rnd__submenu {
  z-index: 1000;
}

.rnd__submenu .rnd__submenu .rnd__submenu  {
  z-index: 1200;
}

.editable-cell {
  cursor: pointer;
  // background-color: #f1f1f3;
}

.react-trello-lane {
  background-color: #f1f1f3 !important;
  }

  // .comPlainTextContentEditable--has-placeholder {
  //   display: none !important;
  // }



.non-editable-cell {
  cursor: default;
  background-color: #67677220;
  }




.tox .tox-edit-area__iframe {
    background: $form-bg;
    color: #555555;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
    background: $form-bg !important;
    color: #555555 !important;
}

.tox-editor-header {
  
  background: $form-bg !important;
}

.tox .tox-menubar {
    background: $form-bg !important;
    color: #555555 !important;
}

.tox  .tox-toolbar-overlord {
    background: $form-bg !important;
    color: #555555 !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
    background: $form-bg !important;
    color: #555555;
}
.tox .tox-mbtn {
    color: #555555;
}


.tox-tinymce {
    border: 1px solid lighten($theme-font-color, 7%);
}

.tox .tox-tbtn svg {
    fill: #555555
}

.btn-file-upload-clear {
    color: lighten($theme-font-color, 7%) !important;
}


.react-tags__selected{
  padding-left: 20px !important;
}

.select__value-container{
  padding-left: 20px !important;
}

.tox-tinymce-inline {
  z-index: 1080 !important;
}

/* CourseBuilder column transitions */
.course-builder-column {
  transition: all 0.3s ease;
}

// Responsibilities List Component Styles
.responsibilities-container {
  display: flex;
  height: 100%;
  min-height: 400px;
}

.tree-container {
  flex: 1;
  overflow: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  margin-right: 20px;
}

.details-container {
  flex: 1;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
  max-width: 400px;
}

.responsibility-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.responsibility-root-item {
  display: flex;
  align-items: center;
}

.responsibility-title {
  font-weight: 500;
}

.responsibility-description {
  font-size: 0.9em;
  color: #666;
}

.detail-row {
  margin-bottom: 15px;
}

.detail-label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #555;
}

.detail-value {
  padding: 5px 0;
}

.empty-message {
  color: #888;
  font-style: italic;
}

.item-actions {
  display: flex;
  align-items: center;
}

.add-btn, .delete-btn {
  color: #555;
  transition: color 0.2s;
}

.add-btn:hover {
  color: #28a745;
}

.delete-btn:hover {
  color: #dc3545;
}


