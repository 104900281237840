.rc-cascader {
  font-size: 14px;
}
// main menu style
.rc-cascader-menus {
  font-size: 14px;
  overflow: hidden;
  background: $theme-background;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  // box-shadow: 0 0 5px #d9d9d9;
  white-space: nowrap;
}
.rc-cascader-menu {
  display: inline-block;
  vertical-align: top;
  min-width: 111px;
  height: 400px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-right: 1px solid #e9e9e9;
  overflow: auto;
}
.rc-cascader-menu:last-child {
  border-right: 0;
}
.rc-cascader-menu-item {
  position: relative;
  padding: 7px 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}
.rc-cascader-menu-item:hover {
  background: darken($theme-background, 3%);
}
.rc-cascader-menu-item-disabled {
  cursor: not-allowed;
  color: #ccc;
}
.rc-cascader-menu-item-disabled:hover {
  background: transparent;
}
.rc-cascader-menu-item-loading:after {
  position: absolute;
  right: 12px;
  content: "...";
}
.rc-cascader-menu-item-active:not(.rc-cascader-menu-item-disabled) {
  background: darken($theme-background, 3%);
  font-weight: bold;
}
.rc-cascader-menu-item-expand {
  position: relative;
}
.rc-cascader-menu-item-expand:after {
  content: '>';
  font-size: 14px;
  color: #999;
  position: absolute;
  right: 15px;
  top: 7px;
}
.rc-cascader-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 14px;
  line-height: 1.5;
  color: #666;
  background-color: $theme-background;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rc-cascader-input:focus {
  border-color: #57c5f7;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(45, 183, 245, 0.2);
}
.rc-cascader-input-placeholder {
  color: $disabled-dark-layout-font;
}
.rc-cascader-input-disabled {
  background: darken($theme-background, 3%);
  cursor: not-allowed;
}
.rc-cascader-picker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  z-index: 1080;
}
.rc-cascader-picker-disabled {
  cursor: not-allowed;
}
.rc-cascader-picker-clear {
  opacity: 0;
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -7px;
  font-size: 14px;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.rc-cascader-picker-clear:hover {
  color: #666;
}
.rc-cascader-picker:hover .rc-cascader-picker-clear {
  opacity: 1;
}
.rc-cascader-picker-arrow {
  content: '\e842';
}
.rc-cascader-picker-open .rc-cascader-picker-arrow {
  content: '\e842';
}

/* New styles for the updated component structure */
.rc-cascader-selector {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  z-index: 1080;
  width: 100%;
  min-height: 43px;
  padding: 0 20px;
  border: 1px solid #d9d9d9;
  border-radius: 0.25rem;
  background-color: $theme-background;
  transition: all 0.3s;
}

.rc-cascader-selection-search {
  flex: 1;
  height: 100%;
}

.rc-cascader-selection-search-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  padding-left: 0;
}

.rc-cascader-selection-placeholder {
  color: $disabled-dark-layout-font;
  pointer-events: none;
  padding: 0 16px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  transition: all 0.3s;
  z-index: 1;
}

.rc-cascader-selection-item {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  font-size: 14px;
  color: #495057;
  top: -30px;
  padding-right: 20px;
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rc-cascader-dropdown {
  position: absolute;
  background: $theme-background;
  border-radius: 0.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1080;
}

.rc-cascader-dropdown-hidden {
  display: none;
}

.rc-cascader-dropdown-menu {
  display: flex;
  z-index: 1080;
}

.rc-cascader-dropdown-menu-item {
  padding: 5px 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.rc-cascader-dropdown-menu-item:hover {
  background: darken($theme-background, 3%);
}

.rc-cascader-dropdown-menu-item-active {
  background: darken($theme-background, 3%);
  font-weight: 600;
}

/* Dark mode styles */
body.datta-dark {
  .rc-cascader-selector {
    background-color: lighten($dark-layout, 3%);
    border: 1px solid  darken($dark-layout, 3%);
    // color: var(--text-color, #c6d1d9);
  }

  .rc-cascader-menus {
    background-color: lighten($dark-layout, 3%);
    border: 1px solid lighten($dark-layout, 10%);
  }
  
  .rc-cascader-selection-search-input {
    color: lighten($dark-layout-font, 10%);
  }
  
  .rc-cascader-selection-placeholder {
    color: $disabled-dark-layout-font;
  }
  
  .rc-cascader-dropdown {
    background-color: var(--form-bg, lighten($dark-layout, 3%));
    border-color: var(--jd-color-border, #495057);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
  
  .rc-cascader-menu {
    border-right-color: var(--jd-color-border, #495057);
    z-index: 1080;
  }
  
  .rc-cascader-menu-item {
    color: $dark-layout-font;
  }
  
  .rc-cascader-menu-item:hover {
    background-color: lighten($dark-layout, 10%);
  }
  
  .rc-cascader-menu-item-active:not(.rc-cascader-menu-item-disabled) {
    background-color: lighten($dark-layout, 15%);
  }
  
  .rc-cascader-menu-item-expand:after {
    color: $dark-layout-font;
  }
  
  .rc-cascader-dropdown-menu-item {
    color: $dark-layout-font;
  }
  
  .rc-cascader-dropdown-menu-item:hover {
    background-color: lighten($dark-layout, 10%);
  }
  
  .rc-cascader-dropdown-menu-item-active {
    background-color: lighten($dark-layout, 15%);
  }

  .rc-cascader-selection-item {
    color: lighten($dark-layout-font, 10%);
  }
}